<template>
    <el-main>
      <el-form class="el-form-search" label-width="120px">
        <el-form-item label="订单编号：">
          <el-input v-model="searchForm.order_sn" size="small" placeholder=""></el-input>
        </el-form-item>
        <el-form-item label="技师姓名">
          <el-input v-model="searchForm.name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：">
          <el-input v-model="searchForm.mobile" size="small"></el-input>
        </el-form-item>
        <el-form-item label="技师类型：">
          <el-select v-model="searchForm.identity_type" size="small">
            <el-option label="全部" value=""></el-option>
            <el-option label="产品合伙人" :value="6"></el-option>
            <el-option label="城市合伙人" :value="7"></el-option>
            <el-option label="市场合伙人" :value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="付款时间：">
          <el-date-picker
            v-model="searchForm.create_time"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button size="small" @click="search" type="primary">搜索</el-button>
          <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="order_sn" label="订单编号" width="200" align="center"></el-table-column>
        <el-table-column prop="nickname" label="服务区域" width="200" align="center"></el-table-column>
        <el-table-column prop="mobile" label="技师姓名" width="200" align="center"></el-table-column>
        <el-table-column prop="identity_type" label="联系电话" width="200" align="center">
          <template v-slot="{ row }">
            {{ row.identity_type == 6 ? '产品合伙人' : row.identity_type == 7 ? '城市合伙人' : '市场合伙人' }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="入驻保证金" width="150" align="center">
          <template v-slot="{ row }">￥{{ row.amount }}</template>
        </el-table-column>
        <el-table-column prop="create_time" label="付款时间" width="230" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
  </template>

  <script>
  import _ from 'lodash';
  import { getDateformat } from '@/util/getDate';
  import Paging from '@/components/paging';
//   import userInfo from '@/components/userInfo';
  export default {
    components: {
      Paging,
    //   userInfo,
    },
    data() {
      return {
        searchForm: {
          page: 1,
          rows: 10,
          order_sn: '',
          name: '',
          mobile: '',
          identity_type: '',
          create_time: [],
        },
        activeName: '1',
        list: [],
        total_number: 0,
        showAudit: !1,
        showAuditInfo: !1,
        showReject: !1,
        currentInfo: {},
        auditInfo: {},
        reason: '',
        serviceClassify: [],
        goodsClassify: [],
        auditForm: {
          status: 0,
          amount: '',
          withdraw_rate: '',
          reason: '',
        },
      };
    },
    filters: {
      getDateformat: getDateformat,
      identityName(type) {
        let name = '';
        switch (type) {
          case 0:
            name = '个人会员';
            break;
          case 1:
            name = '企业会员';
            break;
          case 2:
            name = '个人会员商家';
            break;
          case 3:
            name = '企业会员商家';
            break;
          case 4:
            name = '供应商品商家';
            break;
          case 5:
            name = '代销商家';
            break;
          case 6:
            name = '产品合伙人';
            break;
          case 7:
            name = '城市合伙人';
            break;
          case 8:
            name = '市场合伙人';
            break;
        }
        return name;
      },
    },
    created() {
      this.getList();
      this.$axios.post(this.$api.gateway.service.ServiceClassify).then(res => {
        if (res.code == 0) {
          let list = res.result;
          for (let i in list) {
            let obj = {
              name: list[i].name,
              _child: [],
            };
            if (list[i].type == 2) {
              obj._child.push(...list[i]._child);
            } else {
              for (let y in list[i]._child) {
                obj._child.push(...list[i]._child[y]._child);
              }
            }
            this.serviceClassify.push(obj);
          }
          console.log(this.serviceClassify);
        }
      });
      this.$axios.post('/Gateway' + this.$api.goods.goodsClass).then(res => {
        if (res.code == 0) {
          this.goodsClassify = res.result;
        }
      });
    },
    methods: {
      updateData(val, status) {
        if (status == 0) {
          this.searchForm.rows = val;
        } else {
          this.searchForm.page = val;
        }
        this.getList();
      },
      search() {
        this.searchForm.page = 1;
        this.getList();
      },
      searchClear() {
        this.searchForm = {
          page: 1,
          rows: 10,
          order_sn: '',
          name: '',
          mobile: '',
          identity_type: '',
          create_time: [],
        };
        this.getList();
      },
      getDateformat(val) {
        return getDateformat(val);
      },
      seeApply(row) {
        let goodsClassify = this.goodsClassify;
        let serviceClassify = this.serviceClassify;
        serviceClassify.map(item => {
          item.show = !1;
          item._child.map(item => (item.show = !1));
        });
        goodsClassify.map(item => {
          item.show = !1;
          item._child.map(item => (item.show = !1));
        });
        this.$axios.post(this.$api.gateway.member.auditInfo, { id: row.audit_id }).then(res => {
          if (res.code == 0) {
            let info = res.result;
            if (info.identity_type == 6) {
              for (let i in goodsClassify) {
                for (let y in goodsClassify[i]._child) {
                  for (let k in info.goods) {
                    if (info.goods[k].id == goodsClassify[i]._child[y].id) {
                      goodsClassify[i].show = !0;
                      goodsClassify[i]._child[y].show = !0;
                    }
                  }
                }
              }
            }
            if (info.identity_type == 7) {
              serviceClassify.map(item => {
                item.show = !0;
                item._child.map(item => (item.show = !0));
              });
              goodsClassify.map(item => {
                item.show = !0;
                item._child.map(item => (item.show = !0));
              });
            }
            if (info.identity_type == 8) {
              for (let i in goodsClassify) {
                for (let y in goodsClassify[i]._child) {
                  for (let k in info.markets) {
                    if (info.markets[k].id == goodsClassify[i]._child[y].id) {
                      goodsClassify[i].show = !0;
                      goodsClassify[i]._child[y].show = !0;
                    }
                  }
                }
              }
              for (let i in serviceClassify) {
                for (let y in serviceClassify[i]._child) {
                  for (let k in info.markets) {
                    if (info.markets[k].id == serviceClassify[i]._child[y].id) {
                      serviceClassify[i].show = !0;
                      serviceClassify[i]._child[y].show = !0;
                    }
                  }
                }
              }
              console.log(serviceClassify);
            }
            this.currentInfo = row;
            this.auditInfo = info;
            this.showAuditInfo = !0;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      audit(row) {
        this.currentInfo = row;
        this.showAudit = !0;
        this.auditForm = {
          status: row.status == -1 ? 0 : 1,
          amount: '',
          reason: '',
          withdraw_rate:''
        };
      },
      confirmAudit() {
        let data = {
          id: this.currentInfo.status == -1 ? this.currentInfo.id : this.currentInfo.audit_id,
          status: this.auditForm.status,
        };
        if (this.currentInfo.status == -1) {
          if (data.status == 0) {
            if (this.auditForm.amount) data.amount = this.auditForm.amount;
          } else {
            if (!this.auditForm.reason) return this.$message.warning('请填写驳回原因');
            data.reason = this.auditForm.reason;
          }
        } else {
          if (data.status == 1) {
            if (!this.auditForm.withdraw_rate) return this.$message.warning('请填写提现费率');
            data.withdraw_rate = this.auditForm.withdraw_rate;
          }
          if (data.status == 2) {
            if (!this.auditForm.reason) return this.$message.warning('请填写驳回原因');
            data.reject_reason = this.auditForm.reason;
          }
        }
        this.$axios.post(this.currentInfo.status == -1 ? this.$api.gateway.member.auditPartnerOrder : this.$api.gateway.member.audit, data).then(res => {
          if (res.code == 0) {
            this.$message.success('处理成功过');
            this.getList();
            this.showAudit = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
      getList() {
        let searchForm = _.cloneDeep(this.searchForm);
        for (let i in searchForm.create_time) searchForm.create_time[i] = searchForm.create_time[i] / 1000;
        this.$axios.post(this.$api.gateway.member.partnerOrder, searchForm).then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
  };
  </script>

  <style lang="less" scoped>
  .el-main {
    background: #fff;
  }
  .apply-info {
    .el-form-item {
      margin: 0;
    }
  }
  </style>
